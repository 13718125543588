$(function() {
  var
    // $win = $(window),
    // $mainMenu = $('.js-main-menu'),
    $slider = $('.js-slider'),
    $newsslider = $('.js-news-slider'),
    $scroll = $('.js-scroll'),
    $modal = $('.js-modal');

  // function setMenuPosition() {
  //   var
  //     st = $(this).scrollTop();
  //
  //   if (st >= 530) $mainMenu.addClass('fixed');
  //   else $mainMenu.removeClass('fixed');
  // }

  function scrollTo(offset, duration) {
    offset = offset || 0;
    duration = duration || 1000;
    $('html,body').stop().animate({
      scrollTop: offset
    }, duration, 'easeOutCubic');
  }

  function randomSPO(n, c) {
    var a = [], r;

    while (a.length < n) {
      r = Math.floor(Math.random() * c);

      if(a.indexOf(r) < 0) {
        a.push(r);
      }
    }

    return a;
  }

  function generateSPO() {
    var
      $spo = [
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo01.jpg",
        "name":"Оломоуц — соляные пещеры Величка — Краков: три жемчужины ЮНЕСКО",
        "unesco":true,
        "desc":"Знакомство с «маленькой Прагой» на берегах Моравы",
        "duration":"2 дня",
        "validity":"до 31.10.2017",
        "price":"от 110 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Olomouc"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo02.jpg",
        "name":"Замок Леднице и город Микулов",
        "unesco":false,
        "desc":"Путешествие по ренессансному Микулову и очаровательному замку Южной Моравии",
        "duration":"13 часов",
        "validity":"до 31.10.2017",
        "price":"от 55 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Lednice"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo03.jpg",
        "name":"Кутна Гора и замок Жлебы",
        "unesco":true,
        "desc":"Большая история маленького городка Центральной богемии",
        "duration":"8 часов",
        "validity":"до 31.10.2017",
        "price":"от 30 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Kutna_Hora"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo04.jpg",
        "name":"Чешски Крумлов и замок Глубока над Влтавой",
        "unesco":true,
        "desc":"Поездка в город, затерянный во времени, с посещением одного из самых красивых замков Чехии",
        "duration":"11 часов",
        "validity":"до 31.10.2017",
        "price":"от 35 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Cesky_Krumlov"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo05.jpg",
        "name":"Ждяр над Сазавой",
        "unesco":false,
        "desc":"Экскурсия для тех, кто хочет окунуться в атмосферу мистики и тайн старинной Чехии",
        "duration":"6 часов",
        "validity":"до 31.10.2017",
        "price":"от 70 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Zdar-nad-sazavou"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo06.jpg",
        "name":"Госпиталь Кукс",
        "unesco":false,
        "desc":"Поездка в легендарный исторический комплекс с посещением одной из старейших аптек Европы",
        "duration":"4 часа",
        "validity":"до 31.10.2017",
        "price":"от 32 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Hospital_Kuks"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo07.jpg",
        "name":"Замок Карлова Коруна",
        "unesco":false,
        "desc":"Экскурсия в роскошный чешский дворец, поражающий воображение",
        "duration":"5 часов",
        "validity":"до 31.10.2017",
        "price":"от 47 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Karlova_Koruna"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo08.jpg",
        "name":"Тельч",
        "unesco":false,
        "desc":"Захватывающее путешествие по «моравской Венеции»",
        "duration":"8 часов",
        "validity":"до 31.10.2017",
        "price":"от 67 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Telc"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo09.jpg",
        "name":"Замок Кромержиж",
        "unesco":false,
        "desc":"Экскурсия в резиденцию епископов со всемирно известными летними садами",
        "duration":"11 часов",
        "validity":"до 31.10.2017",
        "price":"от 88 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Kromeriz"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo10.jpg",
        "name":"Замок Вранов над Дией",
        "unesco":false,
        "desc":"Экскурсия для тех, кто хочет познать изысканную культуру дворянской жизни эпохи барокко",
        "duration":"8 часов",
        "validity":"до 31.10.2017",
        "price":"от 87 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Vranov"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo11.jpg",
        "name":"Тройский замок в Праге",
        "unesco":false,
        "desc":"Прогулка по летнему дворцу Праги, окружённому прекрасными садами и виноградниками",
        "duration":"4 часа",
        "validity":"до 31.10.2017",
        "price":"от 32 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Troja"
      },
      {
        "img":"http://uploads.profi.travel.s3.amazonaws.com/storage/static1.profi.travel/projects/czechrepublic/files/spo12.jpg",
        "name":"Литомышль",
        "unesco":false,
        "desc":"Экскурсия в городок восточной Богемии для тех, кто хочет хочет познать красоты ренессансной эпохи",
        "duration":"4 часа",
        "validity":"до 31.10.2017",
        "price":"от 32 € за человека",
        "url":"http://www.veditour.ru/hot/baroque-Litomysl"
      }
    ],
      $w = $('.js-spo');

    if($w.length){
      var c = $w.data('count');

      if(c){
        var a = randomSPO(c, $spo.length);

        for(var i = 0; i < 3; i++){
          $w.append(
            $('<div />').addClass('col-lg-4').append(
              $('<div />').addClass('spo transition').css({backgroundImage:'url(' + $spo[a[i]].img + ')'}).append(
                $('<div />').addClass('spo__body').append(
                  $('<div />').addClass('spo__body-title').append(
                    $('<span />').text($spo[a[i]].name)
                  )
                ).append(
                  $('<div />').addClass('spo__body-desc').html(
                    '<svg class="icon icon_location" viewBox="0 0 1 1">' +
                    '<use xlink:href="#location"></use>' +
                    '</svg>' + $spo[a[i]].desc
                  )
                ).append(
                  $('<div />').addClass('spo__body-text').html(
                    'Продолжительность: <span class="text_black">' + $spo[a[i]].duration + '</span><br>' +
                    'Срок действия: <span class="text_black">' + $spo[a[i]].validity + '</span><br>' +
                    'Стоимость: <span class="text_black">' + $spo[a[i]].price + '</span>'
                  )
                ).append(
                  $('<a />').addClass('btn btn_medium btn_reservation').attr({'href':$spo[a[i]].url,'target':'_blank'}).html(
                    '<svg class="icon icon_check" viewBox="0 0 1 1">' +
                    '<use xlink:href="#check"></use>' +
                    '</svg> Забронировать'
                  )
                )
              )
            )
          );
        }
      }else{
        $.each($spo, function(i){
          $w.append(
            $('<div />').addClass('col-lg-4').append(
              $('<div />').addClass('spo transition').append(
                $('<div />').addClass('spo__img').css({backgroundImage:'url(' + $spo[i].img + ')'}).append(
                  $spo[i].unesco ? $('<i />').addClass('icon icon_unesco') : null
                )
              ).append(
                $('<div />').addClass('spo__body').append(
                  $('<div />').addClass('spo__body-title').append(
                    $('<span />').text($spo[i].name)
                  )
                ).append(
                  $('<div />').addClass('spo__body-desc').html(
                    '<svg class="icon icon_location" viewBox="0 0 1 1">' +
                    '<use xlink:href="#location"></use>' +
                    '</svg>' + $spo[i].desc
                  )
                ).append(
                  $('<div />').addClass('spo__body-text').html(
                    'Продолжительность: <span class="text_black">' + $spo[i].duration + '</span><br>' +
                    'Срок действия: <span class="text_black">' + $spo[i].validity + '</span><br>' +
                    'Стоимость: <span class="text_black">' + $spo[i].price + '</span>'
                  )
                ).append(
                  $('<a />').addClass('btn btn_medium btn_reservation').attr({'href':$spo[i].url,'target':'_blank'}).html(
                    '<svg class="icon icon_check" viewBox="0 0 1 1">' +
                    '<use xlink:href="#check"></use>' +
                    '</svg> Забронировать'
                  )
                )
              )
            )
          );
        });
      }
    }
  }

  generateSPO();

  if($slider.length) {
    $slider.slick({
      infinite:      true,
      slidesToShow:  1,
      slidesToScrol: 1,
      prevArrow:     false,
      nextArrow:     false,
      dots:          true
    });
  }

  if($newsslider.length) {
    $newsslider.slick({
      infinite:      true,
      slidesToShow:  1,
      slidesToScrol: 1,
      prevArrow:     '<svg class="slick-prev transition" viewBox="0 0 1 1"><use xlink:href="#left"></use></svg>',
      nextArrow:     '<svg class="slick-next transition" viewBox="0 0 1 1"><use xlink:href="#right"></use></svg>',
      dots:          true
    });
  }

  if($scroll.length) {
    $scroll.perfectScrollbar({
      wheelPropagation: true,
      minScrollbarLength: 100
    });
  }

  $modal.hide();

  $('.js-anchor')
    .on('click', function() {
      var
        $tName = $($(this).attr('href'));

      if ($tName.length) {
        var scroll = $tName.offset().top;

        scrollTo(scroll, 700);
      }
      return false;
    });

  // $('.js-folder')
  //   .on('click', '.js-btn', function() {
  //     var
  //       $p = $(this).parents('.js-folder').eq(0),
  //       $t = $('.js-body', $p).eq(0);
  //
  //     $p.toggleClass('open');
  //     if ($p.hasClass('open')) $t.slideDown(300);
  //     else $t.slideUp(300);
  //
  //     return false;
  //   });

  // $('.js-reg-double')
  //   .on('click', function() {
  //     scrollTo(0, 700);
  //     $('.js-reg').trigger('click');
  //
  //     return false;
  //   });

  $('.js-webinar')
    .on('change', '.js-checkbox', function() {
      var
        $p = $(this).parents('.js-webinar'),
        $c = $('.js-checkbox', $p),
        $t = $('.js-submit', $p);

      $t.hide();
      $c.each(function() {
        if ($(this).prop('checked')) {
          $t.show();
        }
      });
    });

  $('.js-popup-init')
    .on('click', function(e){
      var
        c = $(this).index() || 0,
        $p = $(this).parent(),
        $m = $('.js-modal', $p),
        $pu = $('.js-popup', $p),
        $s = $('.js-slider', $pu.eq(c));

      $m.css({opacity:0}).show(function(){
        $pu.hide().eq(c).show();
        if($s.length) $s.slick('refresh');
        $m.stop().animate({opacity:1}, 300);
      });

      e.stopPropagation();
      return false;
    });

  $('.js-popup')
    .on('click', '.js-close', function(){
      $modal.fadeOut(300);
    })
    .on('click', function(e){
      e.stopPropagation();
    });

  $('html,body')
    .on('click', function(){
      $modal.fadeOut(300);
    });

  // $win
  //   .on('scroll', setMenuPosition);

});